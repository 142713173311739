import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";

import logo from '../assets/twl-logo.png'

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full h-screen  mx-auto px-4 sm:px-6 lg:px-8">

        <div className="py-16">
          <div className="text-center">
            <p className="text-lg font-semibold text-twl-purple uppercase tracking-wide">
              404 error
            </p>
            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
              Page not found.
            </h1>
            <p className="mt-2 text-base text-gray-500">
              Sorry, we couldn’t find the page you’re looking for.
            </p>
            <div className="mt-6">
              <Link
                to="/"
                className="text-base font-medium text-twl-purple hover:text-indigo-500"
              >
                Go back home<span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default NotFoundPage;
